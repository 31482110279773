<template>
  <div>
    <headea></headea>
    <div class="top-img">
      <img src="../../assets/images/login_bg.png" alt="" />
      <div class="top-tit">
        <h3>欢迎登录</h3>
        <el-form ref="form" :rules="rules" :model="form">
          <el-form-item prop="account">
            <el-input
              prefix-icon="el-icon-user"
              placeholder="请输入您的账号"
              v-model="form.account"
            ></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              prefix-icon="el-icon-key"
              placeholder="请输入密码"
              v-model="form.password"
              show-password
            ></el-input>
          </el-form-item>
          <el-button class="buttona" type="primary" @click="onSubmit"
            >登录</el-button
          >
        </el-form>
      </div>
    </div>

    <sild></sild>
    <bottom></bottom>
  </div>
</template>

<script>
import headea from "../../components/header/index";
import sild from "../../components/sild/side";
import bottom from "../../components/bottom/bottom";
import { login } from "../../api/api";
export default {
  components: {
    headea,
    sild,
    bottom,
  },
  data() {
    return {
      form: {
        account: "",
        password: "",
      },

      rules: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    async onSubmit() {
      const { data: res } = await login(this.form);

      if (res.code == 1) {
        this.$message({
          message: "登录成功",
          type: "success",
        });
        this.$router.push("/up");
        localStorage.setItem("token", res.data);
        localStorage.setItem("usename", this.form.account);
        this.form = {
          account: "",
          password: "",
        };
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.colorChangeae {
  color: #cc2830ff !important;
  font-weight: 600;
}
.colorChangeaeace {
  height: 80px !important;
}
/deep/.el-button {
  width: 100%;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
}
.ts {
  font-size: 20px;

  font-weight: 600;
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 120px;
}
.djj {
  font-size: 20px;
  color: #333333ff;
  span {
    margin-left: 44px;
    cursor: pointer;
  }
}
.top-img {
  width: 1360px;
  height: 100%;
  position: relative;
  margin: 0 auto;
  margin-top: 120px;
  max-height: 37.5rem;
  overflow: hidden;
  min-width: 1200px;
  min-height: 700px;

  img {
    width: 100%;
    height: 100%;
  }
  /deep/.el-input__inner {
    height: 55px;
    font-size: 20px !important;
    margin-bottom: 20px;
  }
  /deep/.el-input__icon {
    font-size: 26px;
    line-height: 50px;
  }
  .top-tit {
    position: absolute;
    width: 500px;
    top: 50%;
    right: 50%;
    height: 560px;
    margin-right: -250px;
    margin-top: -280px;

    border-radius: 15px;
    background-color: #fff;
    color: #000;
    box-shadow: 10px 5px 5px #3333;
    font-size: 32px;
    padding: 80px 80px;
    h3 {
      color: #171c61ff;
      margin-bottom: 40px;
    }
  }
}

.flex {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  .cptit {
    font-size: 30px;
    color: #171c61ff;
    font-weight: 600;
    width: 120px;
    height: 45px;
  }
}

.for {
  width: 384px;
  height: 451px;
  border: 1px solid #efefefff;
  margin-bottom: 24px;
  margin-right: 24px;
  cursor: pointer;
  position: relative;
  img {
    width: 384px;
    height: 229px;
  }
}
.for:nth-child(3n + 3) {
  margin-right: 0;
}
/deep/.number {
  color: #42424257;
}
</style>
